import { render, staticRenderFns } from "./RequestFilter.vue?vue&type=template&id=489481d8&scoped=true&"
import script from "./RequestFilter.vue?vue&type=script&lang=js&"
export * from "./RequestFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "489481d8",
  null
  
)

export default component.exports