var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"attendance-body my-3"},[_c('h3',{staticClass:"attendance-title"},[_vm._v("Order Requests")]),_c('div',{staticClass:"card pt-0"},[_c('h4',{staticClass:"card-header"},[_vm._v("Filter")]),_c('div',{staticClass:"card-body"},[_c('request-filter',{on:{"searchParams":_vm.search}})],1)]),(_vm.orderRequests.length > 0)?_c('div',{},[_c('table',{staticClass:"table table-striped w-100"},[_c('thead',{staticClass:"attendance-head bg-default text-white"},[_c('tr',{staticClass:"w-100"},_vm._l((_vm.tableHeaders),function(header){return _c('th',{staticClass:"mx-3 text-center"},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_c('tbody',_vm._l((_vm.orderRequests),function(item,index){return _c('tr',{staticClass:"text-center",attrs:{"data-hoverable":"true"}},[_c('td',{staticClass:"pointer",on:{"click":function($event){return _vm.viewRequest(item)}}},[_c('span',{staticClass:"user text-white",class:item.status === 'accepted'
										? 'bg-success'
										: item.status === 'declined'
										? 'bg-danger'
										: 'bg-warning'},[_vm._v(_vm._s(index + _vm.OId))])]),_c('td',[_vm._v(" "+_vm._s(_vm._f("upper")((item.customer.first_name + ' ' + item.customer.last_name)))+" ")]),_c('td',[_vm._v(_vm._s(item.customer.telephone))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(item.created_at)))]),_c('td',[_vm._v(_vm._s(item.order_type))]),_c('td',[_vm._v(_vm._s(item.status))]),_c('td',[(
									item.status === 'accepted' || item.status === 'declined'
								)?_c('div',[_vm._v(" N/A ")]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(item.newStat),expression:"item.newStat"}],staticClass:"custom-select w-50 pointer",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(item, "newStat", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.showModal(item.newStat, item)}]}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("--select--")]),_c('option',{attrs:{"value":"decline"}},[_vm._v("Decline")]),_c('option',{attrs:{"value":"accept"}},[_vm._v("Approve")])])])])}),0)])]):_c('div',{staticClass:"mx-4"},[_c('ZeroState',{attrs:{"title":'No Request to display',"message":'No requests'},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('img',{attrs:{"src":require("../../../assets/thumb-up.png")}})]},proxy:true}])})],1),(_vm.pageMeta.total > 10)?_c('BasePagination',{attrs:{"pageParam":_vm.pageMeta},on:{"fetchData":_vm.paginationAction}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }